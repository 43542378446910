const InfoSvg = () => {
  return (
    <svg version="1.0" viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" stroke="none">
        <path
          d="M8167 12755 c-689 -112 -1230 -606 -1369 -1248 -31 -145 -31 -426 0
-572 60 -279 174 -491 356 -663 234 -221 534 -335 921 -348 521 -19 980 162
1325 522 494 516 548 1336 123 1857 -176 215 -445 369 -765 438 -125 27 -462
35 -591 14z"
        />
        <path
          d="M7050 9193 c-937 -70 -1850 -483 -2690 -1217 -150 -131 -753 -733
-863 -861 -229 -269 -354 -473 -364 -595 -8 -100 37 -180 131 -235 163 -95
280 -11 813 587 333 373 499 547 659 687 291 256 519 376 714 376 68 0 93 -4
125 -22 123 -66 177 -211 142 -379 -13 -59 -892 -2196 -1560 -3794 -436 -1041
-695 -1942 -723 -2518 -31 -622 272 -1005 883 -1117 119 -22 428 -31 578 -16
853 81 1816 530 2760 1286 401 322 815 727 1075 1055 203 255 330 491 330 610
0 57 -27 133 -65 183 -45 59 -129 102 -182 94 -88 -15 -228 -143 -672 -617
-472 -505 -702 -722 -916 -865 -275 -184 -460 -213 -574 -90 -22 24 -47 61
-56 81 -66 159 7 468 249 1048 74 175 1456 3756 1571 4066 83 227 150 475 192
715 13 75 17 158 18 335 0 251 -9 319 -59 468 -142 417 -500 671 -1031 732
-83 9 -378 11 -485 3z"
        />
      </g>
    </svg>
  );
};

export { InfoSvg };
