import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.module.scss';
// import ApiCommunication from "@/apiCommunication";

const Index = () => {
    const url = 'https://friends.casino:4000';
    // const url = 'http://localhost:4000'
    React.useEffect(() => {
        // Initialize iframe communication
        import('./iframeCommunication').then(({ default: IframeCommunication }) => {
            const username = `Mr ${Math.floor(Math.random() * 1000)}`;
            const iframeComm = new IframeCommunication('#myIframe', url, username);
            iframeComm.init();

            // Cleanup on component unmount
            // return () => iframeComm.cleanup();
        });
    }, []);

    // React.useEffect(() => {
    //     console.log('---------------------------------------');
    //     // Initialize iframe communication
    //     import('./apiCommunication').then(({ default: ApiCommunication }) => {
    //         const apiComm = new ApiCommunication('#myIframe', url, 'John Doe');
    //         apiComm.init();
    //
    //         // Cleanup on component unmount
    //         return () => apiComm.cleanup();
    //     });
    // }, []);

    return (
        <React.StrictMode>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '100vh'}}>
                <div style={{flex: 1, padding: '20px', boxSizing: 'border-box'}}>
                    <App/>
                </div>
                <iframe
                    id="myIframe"
                    src={`${url}${window.location.pathname}?source=test`}
                    width="100%"
                    height="100%"
                    onLoad={handleIframeLoad}
                    allow="camera; microphone; clipboard-write; clipboard-read; compute-pressure; geolocation"
                />
            </div>
        </React.StrictMode>
    );

    function handleIframeLoad() {
        console.log('Iframe has loaded');
        import('./iframeCommunication').then(({ default: IframeCommunication }) => {
            const username1 = `Mr ${Math.floor(Math.random() * 1000)}`;
            const iframeComm1 = new IframeCommunication('#myIframe', 'https://friends.casino:4000', username1);
            iframeComm1.init();

            // Cleanup on component unmount
            // return () => iframeComm.cleanup();
        });
    }
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<Index/>);

serviceWorkerRegistration.register();
