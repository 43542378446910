const SuccessSvg = () => {
  return (
    <svg aria-hidden={true} fill="none" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m5.5 11c3.0376 0 5.5-2.4624 5.5-5.5s-2.4624-5.5-5.5-5.5-5.5 2.4624-5.5 5.5 2.4624 5.5 5.5 5.5zm-1.3456-2.9122c-0.11065-0.07168-0.19235-0.16414-0.23375-0.25427-0.00853-0.0113-0.01648-0.02325-0.02376-0.03583l-1.8509-3.2059c-0.09459-0.16385-0.03847-0.37335 0.12537-0.46795s0.37334-0.03847 0.46793 0.12538l1.8095 3.134 3.9346-5.2462c0.11349-0.15136 0.32826-0.18203 0.47956-0.06852 0.15137 0.11352 0.18207 0.32824 0.06858 0.4796l-4.1108 5.481c-0.04516 0.06012-0.10621 0.10121-0.17295 0.12177-0.10471 0.07887-0.30894 0.05638-0.49344-0.06307z"
        clipRule="evenodd"
        fill="#fff"
        fillRule="evenodd"
      />
    </svg>
  );
};

export { SuccessSvg };
