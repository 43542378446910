const PortfolioSvg = () => {
  return (
    <svg
      version="1.1"
      viewBox="0 0 512 512"
      aria-hidden={true}
      width="50"
      height="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m502.25 271.57c-18.729 17.964-43.587 27.866-70.036 27.866h-137.86v26.988c0 11.387-9.331 20.723-20.733 20.723h-35.233c-11.397 0-20.726-9.334-20.726-20.723v-26.988h-137.87c-26.445 0-51.308-9.902-70.034-27.866-3.558-3.418-6.815-7.045-9.754-10.852v188.13c0 19.121 15.642 34.749 34.749 34.749h442.51c19.105 0 34.744-15.629 34.744-34.749v-188.13c-2.943 3.807-6.187 7.434-9.753 10.852z" />
      <path d="m477.26 97.896h-442.51c-19.107 0-34.749 15.641-34.749 34.747v88.637c3.359 14.177 10.788 27.213 21.791 37.763 15.468 14.84 36.07 23.02 57.997 23.02h137.87v-26.983c0-11.4 9.329-20.731 20.726-20.731h35.233c11.402 0 20.733 9.329 20.733 20.731v26.982h137.86c21.926 0 42.534-8.179 57.997-23.02 10.993-10.565 18.435-23.59 21.793-37.773v-88.627c0-19.105-15.639-34.746-34.744-34.746z" />
      <path d="m195.24 63.146c0-0.986 0.269-2.427 1.536-3.686 1.254-1.26 2.693-1.533 3.686-1.533h111.08c0.978 0 2.422 0.274 3.679 1.533 1.265 1.26 1.533 2.701 1.533 3.686v17.375h29.53v-17.375c-3e-3 -8.891-3.392-17.782-10.171-24.568-6.789-6.789-15.685-10.181-24.573-10.181h-111.08c-8.893 0-17.795 3.392-24.579 10.181-6.779 6.784-10.173 15.677-10.173 24.568v17.375h29.53v-17.375z" />
      <path d="m273.62 329.77c1.825 0 3.348-1.526 3.348-3.349v-71.344c0-1.823-1.523-3.359-3.348-3.359h-35.236c-1.818 0-3.359 1.536-3.359 3.359v71.344c0 1.823 1.541 3.349 3.359 3.349h35.236z" />
    </svg>
  );
};

export { PortfolioSvg };
